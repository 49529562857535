import React from 'react';
import { graphql, Link } from "gatsby";
import {Breadcrumb, VideoComponent, SvgIcon} from "components/ui";
import { getSrc } from "gatsby-plugin-image"
import Layout from 'components/layout';
import SeoComponent from 'components/seo';

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined"

//Layout include hero component with one column content layout
const VideosWebinarsEntry = ({ location, data }) => {
    const videoWebinar = data.strapiVideo
    const seo = {
      metaTitle: videoWebinar?.title,
      metaDescription: videoWebinar?.description,
      shareImage: getSrc(videoWebinar?.thumbnail?.localFile),
      metaKeywords: videoWebinar?.keywords
    }
    const presentersArray=presentersString=>{
        let presenters=presentersString?.split("\n");
        return presenters;
    }
    const setPresenterContent = presenter=>{
      let presenterContent;
      if(presenter.charAt(0) === "-" || presenter.charAt(0) === "•"){
        presenterContent = "-" + presenter.substring(1)
      } else{
        presenterContent = "- " + presenter;
      }
      return presenterContent;
    }
    let thisLocationPathname = ''

    if (isBrowser) {
        thisLocationPathname = window.location.pathname
    }
    const sortTopics=topicsList=>{
      let filteredList=topicsList.filter(topic=>{
        return topic?.title !== "Videos and Webinars"
      })
      topicsList.includes("Videos and Webinars") && filteredList.push("Videos and Webinars");
      return filteredList;
    }

  return (
    <Layout location={location}>
    <SeoComponent title={seo.metaTitle} description={seo.metaDescription} keywords={seo.metaKeywords} />
    <div className="grid-container">
        <Breadcrumb pathname={thisLocationPathname} customCurrentPage={videoWebinar?.title} />
        <h1 name="main-content" id="main-content">{videoWebinar?.title}</h1>
        <div className="grid-row grid-gap padding-bottom-5">
            <div className="desktop:grid-col-9 tablet:grid-col-12">
                {videoWebinar?.author && <p className="text-bold">{videoWebinar?.author}</p>}
                {(videoWebinar?.video_length && videoWebinar?.date) && <p><span className="text-bold">Date:</span> {videoWebinar?.date} | <span className="text-bold">Length:</span> {videoWebinar?.video_length}</p>}
            </div>
            <VideoComponent
              videoStyle="desktop:grid-col-12"
              videoSrcId={videoWebinar?.video_url.includes("=") ? videoWebinar?.video_url.substring(videoWebinar?.video_url.indexOf('=')+1) : videoWebinar?.video_url.substring(videoWebinar?.video_url.indexOf('.')+4) }
              videoTitle={videoWebinar?.title}
            />
            <div className="desktop:grid-col-9 tablet:grid-col-12">
              {videoWebinar?.body &&
                <div
                    dangerouslySetInnerHTML={{
                        __html: videoWebinar?.body?.data?.childMarkdownRemark?.html,
                        }}
                    />
                }
              {videoWebinar?.presenters &&
                <>
                <h3>Presenter(s):</h3>
                 <ul className="usa-list usa-list--unstyled">
                    {presentersArray(videoWebinar?.presenters?.data?.presenters)?.map((presenter, idx)=>{
                     return (
                      presenter.length > 0 && <span className="display-flex" key={`presenter-${idx+1}`}>
                            <SvgIcon name="presenter" />
                            <li className="padding-bottom-1">
                              {setPresenterContent(presenter)}
                            </li>
                        </span>)
                     })}
                </ul>
                </>
              } 
              {videoWebinar?.topics?.length > 0 && 
                <p className="margin-bottom-0">Topic(s): {sortTopics(videoWebinar?.topics)?.map((topic, idx)=>{
                   return idx === 0 ? topic?.title : ", " + topic?.title
                })}</p>
              }
              {(videoWebinar?.category || videoWebinar?.video_category) &&
                <p className="margin-top-0">Section: {videoWebinar?.category?.name} {(videoWebinar?.category?.name && videoWebinar?.video_category?.name ) && ","}{videoWebinar?.video_category?.name}</p>
              }
              {videoWebinar?.series?.length > 0 &&
                <Link to={`/topics/${videoWebinar?.series[0].topics[0]?.slug}/${videoWebinar?.series[0]?.slug}`}>
                  <SvgIcon name="arrow_back" />Back to series
                </Link>
              }
          </div>
        </div>
    </div>
  </Layout>
  )};
  export const pageQuery = graphql`
  query ($slug: String) {
    strapiVideo(slug: { eq: $slug }) {
        strapi_id
        title
        slug
        author
        description
        keywords
        video_url
        video_length
        presenters {
          data {
            presenters
          }
        }
        body {
          data {
            childMarkdownRemark {
              html
            }
          }
        }
        date(formatString: "MMMM DD, YYYY")
        thumbnail {
          alternativeText
          caption
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        transcript {
          localFile {
            publicURL
            prettySize
          }
        }
        video_category {
          name
        }
        category {
          name
        }
        topics {
          title
        }
        series {
          slug
          topics {
            slug
          }
        }
    }
  }

`

export default VideosWebinarsEntry;
